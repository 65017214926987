import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Javascript asynchronous testing",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:50:16.000Z",
  "url": "/2018/02/javascript-asynchronous-testing/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`While creating my current project, I had some functionality that involved timing and scheduling. I started writing my tests and got stuck with that object, as the tests simply didn`}{`’`}{`t wait for my callbacks and simply ran through the code and exited.`}</p>
    <p>{`This wasn`}{`’`}{`t acceptable as the asynchronous calls were a major part of the functionality. After googling and browsing `}<a parentName="p" {...{
        "href": "http://stackoverflow.com/users/1033518/tzook-bar-noy"
      }}>{`stackoverflow`}</a>{`, I found this nice option on `}<a parentName="p" {...{
        "href": "https://mochajs.org/"
      }}>{`mocha`}</a>{`.`}</p>
    <p>{`You simply add the `}{`“`}{`done`}{`”`}{` variable to your `}{`“`}{`it`}{`”`}{` tests and the test will wait until you call the `}{`“`}{`done`}{`”`}{` callback (it will fail after 2 seconds if you don`}{`’`}{`t do it).`}</p>
    <p>{`You can see the code below that the asynchronous actions may fool you and not get tested as in the first example.`}</p>
    <p>{`Make sure that all the code you write gets passed through!`}</p>
    <p>{`Enjoy, and I really hope I save someone some time from his life 🙂`}</p>
    <h6>{`This test will succeed but will not check the whole test {#thistestwillsucceedbutwillnotcheckthewholetest}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//in mocha describe function

it("this is an asynchronous test", function(){  
    var tester = true;
    expect(tester).to.be.true;

    setTimeout(function() {
        //********************************
        // YOU WILL NEVER GET HERE....
        // THIS IS NOT TESTED
        //********************************
        tester = false;
        expect(tester).to.be.false;

      }, 1000);       
});
`}</code></pre>
    <h6>{`This test will succeed and will properly check the asynchronous action {#thistestwillsucceedandwillproperlychecktheasynchronousaction}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//in mocha describe function

it("this is an asynchronous test", function(done){  
    var tester = true;

    expect(tester).to.be.true;

    setTimeout(function() {
        tester = false;
        expect(tester).to.be.false;
        done();
    }, 1000);       
});
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      